::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Custom scrollbar */

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 7px;
  cursor: pointer;
  background: #52525b;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0);
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

#nprogress .spinner {
  display: none !important;
}

.cursors-layer > .monaco-mouse-cursor-text {
  position: absolute;
  background-color: #fff;
}

/*** Sandpack ***/
/* File Tabs */
.sp-tab-button {
  display: flex !important;
  align-items: center;
  vertical-align: middle;
}
.sp-tab-button span {
  margin-top: 3px;
}
/* File Explorer */
.sp-button[data-active='true'] {
  background-color: var(--sp-colors-bg-input) !important;
}

/*********** Disabled Inputs *************/
input[type='tel']:disabled {
  cursor: not-allowed;
  background: #ccc;
  opacity: 0.5;
}

input[type='text']:disabled {
  cursor: not-allowed;
  background: #ccc;
  opacity: 0.5;
}

input[type='email']:disabled {
  cursor: not-allowed;
  background: #ccc;
  opacity: 0.5;
}

/*********** React Phone Number Input *************/
.PhoneInputInput {
  /* background-color: #18181b !important; */
  border-radius: 0.375rem !important;
  border: 1px solid black !important;
  font-size: 16px !important;
  padding: 6px 12px !important;
  /* color: #f4f4f5 !important; */
}

.PhoneInput--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
/* .PhoneInput select option {
  background-color: #27272a !important;
} */

/*********** Input type number spin button *************/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: #fff;
  opacity: 1;
  /* display: block;
  background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin; */
}

/********* Scrollbar V2 *********/
/* width */
.scrollbar-v2 ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: transparent;
}
/* Track */
.scrollbar-v2 ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollbar-v2 ::-webkit-scrollbar-thumb {
  background: rgba(24, 20, 48, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* Handle on hover */
.scrollbar-v2 ::-webkit-scrollbar-thumb:hover {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

/* editor style */
/** why need this ?
  after render the editor,
  if go to the bootcamp
  the css does not load
 */
.cm-editor {
  position: relative !important;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
}

.cm-scroller {
  display: flex !important;
  align-items: flex-start !important;
  height: 100%;
  overflow-x: auto;
  position: relative;
  z-index: 0;
  font-family: var(--sp-font-mono);
  line-height: var(--sp-font-lineHeight);
}

.cm-gutters {
  background-color: var(--sp-colors-surface1);
  color: var(--sp-colors-disabled);
  border: none;
  padding-left: var(--sp-space-1);
  display: flex;
  height: 100%;
  box-sizing: border-box;
  left: 0;
  z-index: 200;
  font-size: 0.8rem !important;
}

.cm-content[contenteditable='true'] {
  -webkit-user-modify: read-write-plaintext-only;
}

.cm-lineWrapping {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: anywhere;
}

.cm-content {
  caret-color: var(--sp-colors-accent);
  padding: 0 var(--sp-space-4);
  margin: 0;
  flex-grow: 2;
  min-height: 100%;
  display: block;
  box-sizing: border-box;
  outline: none;
}

.scroll-hide {
  overflow: auto;
  overflow: --moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-hide ::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.scroll-hide ::-webkit-scrollbar-track,
.scroll-hide ::-webkit-scrollbar-thumb,
.scroll-hide ::-webkit-scrollbar-thumb:hover {
  display: none;
}

.full-bleed {
  box-shadow: 0 0 0 100vmax #1a1b1f;
  clip-path: inset(0 -100vmax);
}

/* shadcn */
@layer base {
  :root {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 240 3.7% 15.88%;
    --popover-foreground: 220 14.29% 95.88%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    /*--primary: 210 40% 98%;*/
    /*--primary-foreground: 222.2 47.4% 1.2%;*/

    /*--secondary: 222.2 47.4% 11.2%;*/
    /*--secondary-foreground: 210 40% 98%;*/

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }

  /*.dark {*/
  /*  --background: 224 71% 4%;*/
  /*  --foreground: 213 31% 91%;*/

  /*  --muted: 223 47% 11%;*/
  /*  --muted-foreground: 215.4 16.3% 56.9%;*/

  /*  --accent: 216 34% 17%;*/
  /*  --accent-foreground: 210 40% 98%;*/

  /*  --popover: 224 71% 4%;*/
  /*  --popover-foreground: 215 20.2% 65.1%;*/

  /*  --border: 216 34% 17%;*/
  /*  --input: 216 34% 17%;*/

  /*  --card: 224 71% 4%;*/
  /*  --card-foreground: 213 31% 91%;*/

  /*  --primary: 210 40% 98%;*/
  /*  --primary-foreground: 222.2 47.4% 1.2%;*/

  /*  --secondary: 222.2 47.4% 11.2%;*/
  /*  --secondary-foreground: 210 40% 98%;*/

  /*  --destructive: 0 63% 31%;*/
  /*  --destructive-foreground: 210 40% 98%;*/

  /*  --ring: 216 34% 17%;*/

  /*  --radius: 0.5rem;*/
  /*}*/
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

@keyframes pulse-animation {
  30% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.2);
  }
}
